import logo from './logo.svg';
import './App.css';
import {Outlet, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import {PageLayout} from "./layout/PageLayout";
import {AppProvider} from "./contexts/AppContext";
import Categories from "./pages/Categories";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query'
import BlogPostPage from "./pages/BlogPostPage";

function App() {
    const queryClient = new QueryClient()

    function RoutesBuilder() {
        return (
            <Routes>
                <Route path="/" element={<Home/>} index={true}/>
                <Route path={"/categories"} element={<Categories/>}>
                    <Route path={":categoryId"}/>
                </Route>
                <Route path={"/:alias"} element={<BlogPostPage />}/>
                <Route path={"/post"} element={<BlogPostPage />}>
                    <Route path={":blogId"} />
                </Route>
            </Routes>
        );
    }

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <AppProvider>
                    <PageLayout>
                        <RoutesBuilder/>
                        <Outlet/>
                    </PageLayout>
                </AppProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </div>
    );
}

export default App;
