import {createTheme, ThemeProvider} from "@mui/material";
import {useAppContext} from "../contexts/AppContext";
import {NavigationBar} from "../components/NavigationBar";

export const PageLayout = (props) => {
    const appContext = useAppContext();
    const theme = createTheme({
        palette: {
            primary: {main: "#F58821", contrastText: "#fff"},
            secondary: {main: "#4B525B", contrastText: "#fff"},
        }
    })

    return (
        <>
            <ThemeProvider theme={theme}>
                <NavigationBar/>
                <div className={"page-content"}>
                    {props.children}
                </div>
            </ThemeProvider>
        </>
    );
}