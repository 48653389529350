import './NavigationBar.scss';

import {Nav, Navbar, Button} from 'react-bootstrap';
import logo from './assets/gun24-logo.png';
import React, {useEffect, useState} from "react";
import Hamburger from 'hamburger-react';
import SearchIcon from '@mui/icons-material/Search';
import CategoryIcon from '@mui/icons-material/Category';
import HomeIcon from '@mui/icons-material/Home';
import i18n from "../../i18n";
import {BottomNavigation, BottomNavigationAction, Paper, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import {useAppContext} from "../../contexts/AppContext";


export const NavigationBar = () => {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [activeValue, setActiveValue] = useState('/');
    const [smallHeader, setSmallHeader] = useState(false);

    const appContext = useAppContext();
    const navigate = useNavigate();
    const locales = {
        en: {localeCaption: 'en'},
        de: {localeCaption: 'de'},
        fr: {localeCaption: 'fr'}
    };

    const navLinks = [
        {linkTo: "/", caption: "Home", icon: <HomeIcon/>},
        // {linkTo: "/categories", caption: "Kategorien", icon: <CategoryIcon/>},
        // {linkTo: "/search", caption: "Suche", icon: <SearchIcon/>}
    ]

    const {t} = useTranslation();

    useEffect(() => {
        const onScroll = () => {
            //For understanding the functionality
            let offset = 65;
            let imgHeightBig = 120;
            let imgHeightSmall = 60
            let diff = imgHeightBig - imgHeightSmall;

            if(window.scrollY < (offset-diff)){
                setSmallHeader(false);
            }
            else if(window.scrollY > offset){
                setSmallHeader(true);
            }
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);

    }, [])

    function closeNavBar() {
        setIsMobileNavOpen(false);
    }

    function renderNavLinks() {
        return navLinks.map((navLink, index) => (
            <Nav.Item key={index} className='nav-link-custom' style={{marginRight: '0.7em'}}>
                <NavLink to={navLink.linkTo} onClick={closeNavBar}>{navLink.caption}</NavLink>
            </Nav.Item>
        ))
    }

    return (
        <>
            <div className={"navbar-container"}>
                <Navbar bg="#4b525b" variant="dark" className="navbarStyle">
                    <Navbar.Brand href="/" className={"branding"}>
                        <img src={logo} alt="Logo" className={'logo ' + (smallHeader ? "small-logo" : "")}/>
                    </Navbar.Brand>
                </Navbar>
                <Navbar expand={"lg"} style={{justifyContent: "flex-end", background: "#fff", paddingBottom: "0"}} expanded={isMobileNavOpen}
                        className={"Responsive-Nav-Bar-Wrapper"}>
                    <Navbar.Toggle style={{border: 'none'}} onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
                        <Hamburger toggled={isMobileNavOpen} toggle={setIsMobileNavOpen} color={'#f3791b'}/>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav"
                                     className={"Responsive-Nav-Bar"}>
                        <Nav>
                            {renderNavLinks()}
                        </Nav>

                        {/*<Nav className={` ${isMobileNavOpen ? 'active' : ''}`}>
                        <Stack direction={'row'} style={{marginLeft: 'auto'}}>
                            {Object.keys(locales).map((locale) => (
                                <li id='language-list' key={locale}>
                                    <Button
                                        variant="link"
                                        className="nav-link-custom"
                                        style={{
                                            fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal'
                                        }}
                                        onClick={() => i18n.changeLanguage(locale)}
                                    >
                                        {locales[locale].localeCaption}
                                    </Button>
                                </li>
                            ))}
                        </Stack>
                    </Nav>*/}
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}
                   className={"Mobile-Bottom-Navigation-Wrapper"}>
                <BottomNavigation
                    showLabels
                    value={activeValue}
                    onChange={(event, newValue) => {
                        setActiveValue(newValue);
                        navigate(newValue);
                    }}
                >
                    {navLinks.map(x => (
                        <BottomNavigationAction key={x.linkTo} label={x.caption} value={x.linkTo} icon={x.icon}/>
                    ))}
                </BottomNavigation>
            </Paper>
        </>
    );
};
