import React from 'react';
import './BlogPostList.scss';
import {Card, Grid, Stack} from "@mui/material";
import BlogPost from "../BlogPost";
import {useQuery} from "@tanstack/react-query";
import {GetPosts} from "../../services/BlogService";

export default function BlogPostList() {
    const {isPending, error, data: query} = useQuery({
        queryKey: ['blogPostList'],
        queryFn: () => GetPosts()
    })

    if (isPending) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    return (
        <Stack xs={12} className={"blog-post-container"}>
            {
                query.data.map(post => (
                    <BlogPost post={post} key={post.blogId}/>
                ))
            }
        </Stack>

    );
}