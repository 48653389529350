import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {GetPost} from "../../services/BlogService";
import {Stack, Typography} from "@mui/material";
import {createMarkup} from "../../helper/HtmlHelper";
import './BlogPostPage.scss';
import {useEffect} from "react";
export default function BlogPostPage(){
    const {alias, blogId} = useParams();
    const {isPending, error, data: query} = useQuery({
        queryKey: ['blogPost', alias, blogId],
        queryFn: () => GetPost(blogId, alias),
    });

    useEffect(() => {
        if(query && query.data){
            document.title = query.data.title;
        }
    }, [query]);

    if(isPending)
        return <span>Loading ...</span>

    if (error) return 'An error has occurred: ' + error.message;

    return (
        <Stack className={"blog-post-content"} direction={"column"} spacing={2} sx={{padding: "1em"}}>
            <h1>{query.data.title}</h1>
            <div dangerouslySetInnerHTML={createMarkup(query.data.shortContent)}/>
            {query.data.titleImage && <img src={`https://www.gun24.at${query.data.titleImage}`} className={"title-image"}/>}
            <div dangerouslySetInnerHTML={createMarkup(query.data.fullContent)}/>
        </Stack>
    );
}