import purify from "dompurify";

function createMarkup(html) {
    let decodedHtml = decodeHtml(html);
    return {
        __html: purify.sanitize(decodedHtml)
    }
}

function decodeHtml(encodedXml) {
    const doc = new DOMParser().parseFromString(encodedXml, "text/html");
    return doc.documentElement.textContent;
}

export {decodeHtml, createMarkup};