import React, {useEffect, useState} from 'react';
import './BlogPost.scss';
import {Grid, Typography} from "@mui/material";
import PropTypes from "prop-types";
import purify from 'dompurify';
import {useNavigate} from "react-router-dom";
import {createMarkup} from "../../helper/HtmlHelper";

BlogPost.propTypes = {
    post: PropTypes.object.isRequired
}
export default function BlogPost(props) {
    const [post, setPost] = useState(props.post)
    const dateFormatter = new Intl.DateTimeFormat('de-DE');
    const navigate = useNavigate();

    useEffect(() => {
        setPost(props.post);
    }, [props.post]);

    const textContainerSize = post.titleImage ? 8 : 12;

    function handleNavigateToPost() {
        if (post.alias)
            navigate("/" + post.alias)
        else navigate("/post/" + post.blogId)
    }

    function renderShowOnStart() {
        return (
            <>
                {post.titleImage && <Grid item xs={12} justifyContent={"center"} alignItems="center"
                                          className={"blog-image-container hero-post-image"}>
                    <img src={`https://www.gun24.at${post.titleImage}`} className="blog-card-image" alt={"titleImage"}/>
                </Grid>}
                <Grid item xs={12}>
                    <div className={"regular-blog-description"} style={{fontSize: "12pt"}}
                         dangerouslySetInnerHTML={createMarkup(post.shortContent)}/>
                </Grid>
            </>
        );
    }

    function renderNormalPost() {
        return (
            <>
                <Grid item xs={textContainerSize}>
                    <div className={"regular-blog-description"} style={{fontSize: "12pt"}}
                         dangerouslySetInnerHTML={createMarkup(post.shortContent)}/>
                </Grid>
                {post.titleImage && <Grid item xs={4} justifyContent={"center"} alignItems="center"
                                          className={"blog-image-container"}>
                    <img src={`https://www.gun24.at${post.titleImage}`} className="blog-card-image" alt={"titleImage"}/>
                </Grid>
                }
            </>
        );
    }

    return (
        <Grid container className={"blog-card" + (post.showOnStart ? ' hero-post' : '')} justifyContent={"center"}
              onClick={handleNavigateToPost}>
            <Grid item xs={12}>
                <Typography className={"regular-blog-title"} sx={{fontSize: "20px"}}>{post.title}</Typography>
            </Grid>

            {post.showOnStart ? renderShowOnStart() : renderNormalPost()}

            <Grid container item xs={12} className={"test"}>
                {/*<Grid item xs={6} className={"reading-time"}>*/}
                {/*    <Typography sx={{fontSize: "10px"}}>reading Time: 5min</Typography>*/}
                {/*</Grid>*/}
                <Grid item xs={6} className={"post-date"}>
                    <Typography sx={{fontSize: "10px"}}>{dateFormatter.format(new Date(post.createdDate))}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}


var json = {

    "json": {
        "blogId": 7,
        "title": "Waffenverkauf privat. Wie funktioniert´s?",
        "titleImage": "/assets/img/blog/sell_weapon_private.jpg",
        "alias": "waffenverkauf-privat",
        "shortContent": "",
        "fullContent": "",
        "createdDate": "2021-10-18T16:25:39Z",
        "lastEdit": "2021-10-19T15:08:45Z",
        "showOnStart": true,
        "highlighted": false,
        "category": null
    }
}