// noinspection JSXUnresolvedComponent

import {createContext, useState, useContext} from "react";

const AppContext = createContext(undefined);

function AppProvider({children}) {
    const [locale, setLocale] = useState("de-ch");

    return (
        <AppContext.Provider value={{locale, setLocale}}>
            {children}
        </AppContext.Provider>
    );
}

function useAppContext(){
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within a AppContextProvider');
    }
    return context;
}

export {AppProvider, AppContext, useAppContext};