import axios from "axios";

const GetPosts = () => {
    return axios.get("https://api-blog.gun24.at/Blog");
}

const GetPost = (id, alias) => {
    if (id)
        return axios.get("https://api-blog.gun24.at/Blog/" + id);
    else return axios.get("https://api-blog.gun24.at/Blog/" + alias);
}

const GetCategories = () => {

}

const GetCategory = () => {

}

export {GetPost, GetPosts, GetCategories, GetCategory}