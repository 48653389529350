import {Grid, Typography} from "@mui/material";
import BlogPostList from "../../components/BlogPostList";
import './Home.scss';
import {useEffect} from "react";

export default function Home() {
    useEffect(() => {
        document.title = "Gun24 Blog";
    }, []);

    return (
        <>
            <Grid container className={"main-title-blog-home"}>
                <Grid item>
                    <Typography variant={"h2"} sx={{fontSize: "30pt"}}>Neueste Posts</Typography>
                </Grid>
            </Grid>
            <BlogPostList />
        </>
    );
}
